import React from 'react';

import CloseButton from './CloseButton';
import { ThemedToastContainer } from './styledComponents';

ThemedToastContainer.defaultProps = {
  hideProgressBar: true,
  position: 'bottom-left',
  closeButton: <CloseButton />,
};

export default ThemedToastContainer;
