import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { IntercomUser } from '@app-lib/account/typings';

interface ServiceHookOptions {
  isAuthenticated: boolean;
  intercomUser?: IntercomUser;
}

export function useIntercom({
  isAuthenticated,
  intercomUser,
}: ServiceHookOptions): void {
  const router = useRouter();

  useEffect(() => {
    function updateIntercom(): void {
      if (intercomUser) {
        window.Intercom('update', {
          user_id: intercomUser.user_id.toString(),
          user_hash: intercomUser.web_user_hash,
          email: intercomUser.email,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore last_request_at is included as a data attribute to update
          // https://www.intercom.com/help/en/articles/170-integrate-intercom-in-a-single-page-app
          last_request_at: new Date().getTime() / 1000,
        });
      }
    }

    updateIntercom();

    router.events.on('routeChangeStart', updateIntercom);

    return (): void => {
      router.events.off('routeChangeStart', updateIntercom);
    };
  }, [intercomUser, isAuthenticated]);
}
