/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ApolloProvider } from '@apollo/react-hooks';
import App, { AppContext, AppInitialProps } from 'next/app';
import Head from 'next/head';
import { ErrorInfo, ReactElement } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import * as toastify from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import * as errorHelper from '@app-lib/common/helper/error';
import * as notificationHelper from '@app-lib/common/helper/notification';
import { CloudinaryContext } from '@legacy-components/ResponsiveImage';
import { FoodlesThemeProvider } from '@legacy-components/theming';

import { getUserRoles } from '../authentication/helper';
import { UserRoleContext } from '../authentication/hooks';
import { Role } from '../authentication/typings';
import GlobalStyle from '../common/components/GlobalStyle';
import ThemedToastContainer from '../common/components/ThemedToastContainer';
import I18nContext from '../common/containers/I18nContext';
import I18nProvider from '../common/containers/I18nProvider';
import StripeProvider from '../common/containers/StripeProvider';
import ThirdPartyServicesConfig from '../common/containers/ThirdPartyServicesConfig';
import { WithApolloClientProps, withApolloClient } from '../core/apollo';
import config from '../core/config';
import * as sentryHelper from '../core/helper/sentry';
import { CustomReq } from '../typings';
import { ThemeProvider } from '@mui/material/styles';
import theme from '@app/theme/theme';

// Sentry setup
sentryHelper.init();

// Setup error handler
errorHelper.setupErrorProvider(sentryHelper.captureException);

// Setup notifier
notificationHelper.setupNotifier(
  (
    message: string,
    type?: notificationHelper.NotificationType,
  ): void | Promise<void> => {
    if (!type) {
      toastify.toast(message);
      return;
    }
    toastify.toast(message, { type });
  },
);

interface InitialProps extends AppInitialProps {
  locale: string;
  initialNow: number;
  roles?: Role[];
}

interface Props extends InitialProps, WithApolloClientProps {}

export class CustomerApp extends App<Props, InitialProps> {
  static async getInitialProps({
    Component,
    ctx,
  }: AppContext): Promise<InitialProps> {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const { locale } = (ctx.req as CustomReq) || window.__NEXT_DATA__.props;
    const initialNow = Date.now();
    const pageProps = Component.getInitialProps
      ? await Component.getInitialProps(ctx)
      : {};
    const roles = getUserRoles(ctx);

    return { locale, initialNow, pageProps, roles };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    sentryHelper.captureException(error, errorInfo);

    super.componentDidCatch(error, errorInfo);
  }

  render(): ReactElement {
    const {
      Component,
      pageProps,
      locale,
      apolloClient,
      roles = [],
    } = this.props;
    return (
      <>
        <Head>
          <title>Foodles</title>
        </Head>
        <ApolloProvider client={apolloClient!}>
          <StripeProvider isAuthorized={pageProps?.isAuthorized}>
            <I18nContext.Provider value={{ locale }}>
              <I18nProvider>
                <FoodlesThemeProvider>
                  <ThemeProvider theme={theme}>
                    <UserRoleContext.Provider value={roles}>
                      <CloudinaryContext.Provider value={config.cloudinary}>
                        <ThirdPartyServicesConfig
                          isAuthenticated={pageProps?.isAuthenticated}
                        />
                        <GlobalStyle />
                        <Component {...pageProps} />
                        <ThemedToastContainer />
                      </CloudinaryContext.Provider>
                    </UserRoleContext.Provider>
                  </ThemeProvider>
                </FoodlesThemeProvider>
              </I18nProvider>
            </I18nContext.Provider>
          </StripeProvider>
        </ApolloProvider>
      </>
    );
  }
}

export default withApolloClient(CustomerApp);
