import { ReactElement } from 'react';
import { G, Svg } from 'svgs';

import { ThemedSymbolProps } from './typings';

const Placeholder = ({
  theme, 
  active, 
  color, 
  ...svgProps 
}: ThemedSymbolProps): ReactElement => (
  <Svg {...svgProps}>
    <G />
  </Svg>
);

export default Placeholder;
