import { createContext } from 'react';

import config from '../../../core/config';

export interface Props {
  locale: string;
}

const I18nContext = createContext<Props>({
  locale: config.defaultLanguage,
});

export default I18nContext;
