import { FC, ReactElement } from 'react';
import { Props, ResponsiveImageProps } from './types';
import { useCloudinary } from './hooks';
import { resizeImage } from './helper';

// FIXME For the web we will use a fixed resolution for all pictures until the following ticket fix
//  the responsive plugin
// https://github.com/cloudinary/frontend-frameworks/issues/93
const DEFAULT_SIZE = 512;

const ResponsiveImage: FC<Props> = (props: Props): ReactElement => {
  const cloudinary = useCloudinary();
  const { imageId } = props as ResponsiveImageProps;
  const source = imageId ? { uri: resizeImage({ cloudinary, imageId, size: DEFAULT_SIZE }) } : (props.source ?? undefined);
  return <img {...props} src={source?.uri} />;
};

export default ResponsiveImage;
export * from './types';
export * from './CloudinaryContext';
