import { MessageFormatElement } from '@formatjs/icu-messageformat-parser';

import React, { ReactElement, ReactNode, useContext } from 'react';

import enTranslations from '@translations/customer-app/en.json';
import frTranslations from '@translations/customer-app/fr.json';

import I18nContext from '../I18nContext';
import WrappedIntlProvider from './WrappedIntlPovider';

const translations: Record<string, Record<string, MessageFormatElement[]>> = {
  fr: frTranslations,
  en: enTranslations,
};

interface Props {
  children: ReactNode;
}

const I18nProvider = ({ children }: Props): ReactElement => {
  const { locale } = useContext(I18nContext);
  const messages = translations[locale];
  return (
    <WrappedIntlProvider
      locale={locale}
      messages={messages}
      defaultRichTextElements={{
        b: (chunks: React.ReactNode[]): ReactElement => <b>{chunks}</b>,
      }}
      textComponent={React.Fragment}
    >
      {children}
    </WrappedIntlProvider>
  );
};

export { default as RawI18nProvider } from './WrappedIntlPovider';

export default I18nProvider;
