import { useLazyQuery } from '@apollo/react-hooks';
import { Elements } from '@stripe/react-stripe-js';
import { Stripe, loadStripe } from '@stripe/stripe-js';
import { PropsWithChildren, ReactElement, useEffect, useState } from 'react';

import { Nullable } from '@app-lib/common/typings';
import {
  PAYMENT_CONFIG_QUERY,
  PaymentConfigData,
} from '@app-lib/payment/query';

import * as sentryHelper from '../../../core/helper/sentry';

interface Props {
  isAuthorized: boolean;
}

const StripeProvider = ({
  children,
  isAuthorized,
}: PropsWithChildren<Props>): ReactElement => {
  const [stripe, setStripe] = useState<Nullable<Stripe>>(null);
  const [getPaymentConfig, { called, error, data }] =
    useLazyQuery<PaymentConfigData>(PAYMENT_CONFIG_QUERY, {
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

  if (isAuthorized && !called) {
    getPaymentConfig();
  }

  useEffect(() => {
    if (error) {
      sentryHelper.captureException(error);
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    } else if (data?.paymentConfig?.stripe) {
      void (async function asyncInitStripe() {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const stripe = await loadStripe(data.paymentConfig.stripe!.public_key);
        setStripe(stripe);
      })();
    }
  }, [data, error]);

  return <Elements stripe={stripe}>{children}</Elements>;
};

export default StripeProvider;
