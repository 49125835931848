/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createGlobalStyle } from 'styled-components';

// @ts-ignore no type declaration for fonts
import FuturaNowText from '../../fonts/FuturaNowText.ttf';
// @ts-ignore no type declaration for fonts
import MerlodNorme from '../../fonts/MerlodNorme.ttf';

const GlobalStyle = createGlobalStyle`
 @font-face {
    font-family: 'Merlod Norme';
    src: url(${MerlodNorme}) format('truetype');
  }
  
  @font-face {
    font-family: 'Futura Now Text';
    src: url(${FuturaNowText}) format('truetype');
  }
  
  html, body {
    height: 100%;
    margin: 0;
  }
  html {
    scroll-behavior: smooth;
    font-size: 16px;
  }
  body {
    font-family: -apple-system, BlinkMacSystemFont, sans-serif, "Segoe UI", Roboto, Ubuntu;
  }

  table, caption, tbody, tfoot, thead, tr, th, td {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
  }
`;

export default GlobalStyle;
