import { createTheme } from '@mui/material/styles';
import palette from './palette';
import { Color } from '@legacy-components/theme';

declare module '@mui/material/styles' {
  type ColorShade = 'main';

  interface PaletteColor {
    main: string;
  }

  interface SimplePaletteColorOptions {
    main: string;
  }

  interface Palette {
    blue: PaletteColor;
    danger: PaletteColor;
    primary: PaletteColor;
    light: PaletteColor;
    poultry: PaletteColor;
  }

  interface PaletteOptions {
    blue: PaletteOptions['primary'];
    danger: PaletteOptions['primary'];
    light: PaletteOptions['primary'];
    poultry: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    [Color.PRIMARY]: true;
    [Color.SECONDARY]: true;
    [Color.TERTIARY]: true;
    [Color.QUATERNARY]: true;
    [Color.DANGER]: true;
    [Color.DANGER_ALT]: true;
    [Color.SUCCESS]: true;
    [Color.SUCCESS_ALT]: true;
    [Color.SUNNY]: true;
    [Color.LIGHT]: true;
    [Color.PRIMARY_ALT]: true;
    [Color.SECONDARY_ALT]: true;
    [Color.TERTIARY_ALT]: true;
    [Color.QUATERNARY_ALT]: true;
    [Color.SUNNY_ALT]: true;
    [Color.DARK_ALT]: true;
    [Color.GRAY_100]: true;
    [Color.GRAY_200]: true;
    [Color.GRAY_300]: true;
    [Color.GRAY_400]: true;
    [Color.GRAY_500]: true;
    [Color.DARK]: true;
    [Color.DISABLED]: true;
    [Color.POULTRY]: true;
    [Color.GLUTEN_FREE]: true;
    [Color.RED_MEAT]: true;
    [Color.SEA_SHELL]: true;
    [Color.LACTOSE_FREE]: true;
    [Color.PORK]: true;
    [Color.RABBIT]: true;
    [Color.VEGGIE]: true;
    [Color.SPICY]: true;
    [Color.ALCOHOL]: true;
    [Color.TWITTER]: true;
    [Color.INSTAGRAM]: true;
    [Color.FACEBOOK]: true;
    [Color.TABLE_ROW]: true;
    [Color.PRIMARY_LIGHT]: true;
    [Color.SECONDARY_LIGHT]: true;
    [Color.TERTIARY_LIGHT]: true;
    [Color.QUATERNARY_LIGHT]: true;
    [Color.SUNNY_LIGHT]: true;
    [Color.DANGER_LIGHT]: true;
    [Color.SUCCESS_LIGHT]: true;
    [Color.DARK_LIGHT]: true;
    [Color.DISABLED_LIGHT]: true;
    [Color.POULTRY_LIGHT]: true;
    [Color.GLUTEN_FREE_LIGHT]: true;
    [Color.RED_MEAT_LIGHT]: true;
    [Color.SEA_SHELL_LIGHT]: true;
    [Color.LACTOSE_FREE_LIGHT]: true;
    [Color.PORK_LIGHT]: true;
    [Color.RABBIT_LIGHT]: true;
    [Color.VEGGIE_LIGHT]: true;
    [Color.SPICY_LIGHT]: true;
    [Color.ALCOHOL_LIGHT]: true;
    [Color.EXPIRATION_SALE_BACKGROUND]: true;
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    blue: true;
    danger: true;
    light: true;
    poultry: true;
  }
}

const theme = createTheme({
  palette,
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
  },
});

export default theme;
