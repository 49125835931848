import { useLazyQuery } from '@apollo/react-hooks';

import { CLIENT_INTERCOM_QUERY } from '@app-lib/account/query';
import { IntercomUser } from '@app-lib/account/typings';

import { useIntercom } from './hooks';

interface Props {
  isAuthenticated?: boolean;
}

const ServiceConfig = ({ isAuthenticated = false }: Props): null => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const [getClientIntercom, { data, error, loading }] = useLazyQuery(
    CLIENT_INTERCOM_QUERY,
  );
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
  const intercomUser: IntercomUser = data?.intercom;
  useIntercom({ isAuthenticated, intercomUser });

  if (isAuthenticated && !data && !loading && !error) {
    getClientIntercom();
  }

  return null;
};

export default ServiceConfig;
