import React, { ReactElement } from 'react';
import { ToastContainer, ToastContainerProps } from 'react-toastify';

export interface Props extends ToastContainerProps {
  className?: string;
}

const WrappedToastContainer = ({ className, ...rest }: Props): ReactElement => (
  <div className={className}>
    <ToastContainer {...rest} />
  </div>
);

export default WrappedToastContainer;
