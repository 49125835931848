import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { createContext, useContext } from 'react';

import { Role } from './typings';

export const UserRoleContext = createContext<Role[]>([]);

export function useUserRoles(): Role[] {
  return useContext(UserRoleContext);
}

export function useHasRole(role: Role): boolean {
  const userRoles = useUserRoles();
  return userRoles.includes(role);
}

export function useIsManager(): boolean {
  return useHasRole(Role.MANAGER);
}

interface PasswordStrength {
  strong_password: boolean;
}
interface PasswordStrengthResult {
  passwordStrength?: PasswordStrength;
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const PASSWORD_STRENGTH_QUERY = gql`
  query PasswordStrength($domain: String!) {
    passwordStrength(domain: $domain)
      @rest(
        type: "PasswordStrength"
        path: "/password-strength-rule/?domain={args.domain}"
        method: "GET"
      ) {
      strong_password
    }
  }
`;

export function usePasswordStrengthFromEmail(email: string): boolean {
  const { data: passwordData } = useQuery<PasswordStrengthResult>(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    PASSWORD_STRENGTH_QUERY,
    {
      variables: { domain: email.split('@')[1] ?? '' },
      fetchPolicy: 'no-cache',
      ssr: false,
    },
  );

  return passwordData?.passwordStrength?.strong_password ?? false;
}
